import { ApiWithdrawalFee } from '../services/fees.service';

export enum FeeTypes {
  FROM_ACCOUNT = 'FROM_ACCOUNT',
  FROM_AMOUNT = 'FROM_AMOUNT',
  ZOOM_TOKEN = 'ZOOM_TOKEN',
}

export class FeePayment {
  constructor(readonly label: string, readonly value: FeeTypes) {}
}

export const FEE_PAYMENT_METHODS = [
  new FeePayment('From Account', FeeTypes.FROM_ACCOUNT),
  new FeePayment('From Amount', FeeTypes.FROM_AMOUNT),
  new FeePayment('From Zoom Token', FeeTypes.ZOOM_TOKEN),
];

export enum FeeCalculation {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
  HIGHEST = 'HIGHEST',
  LOWEST = 'LOWEST',
}

export enum GatewayFeeType {
  CRYPTO_DEPOSIT = 'CRYPTO_DEPOSIT',
  CRYPTO_WITHDRAW = 'CRYPTO_WITHDRAW',
  ACH_DEPOSIT = 'ACH_DEPOSIT',
  ACH_WITHDRAW = 'ACH_WITHDRAW',
  ACH_WITHDRAW_SAME_DAY = 'ACH_WITHDRAW_SAME_DAY',
  WIRE_DEPOSIT = 'WIRE_DEPOSIT',
  WIRE_WITHDRAW = 'WIRE_WITHDRAW',
  DEBIT_CARD_DEPOSIT = 'DEBIT_CARD_DEPOSIT',
  DEBIT_CARD_WITHDRAW = 'DEBIT_CARD_WITHDRAW',
  CARD_TOPUP = 'CARD_TOPUP',
}

export class DepositWithdrawalFee {
  constructor(
    readonly calculationType: FeeCalculation,
    readonly fixedAmount: number | null,
    readonly percentageAmount: number | null,
    readonly feeType?: GatewayFeeType
  ) {}

  static build(f: ApiWithdrawalFee) {
    return new DepositWithdrawalFee(f.feeCalculationEn, f.fixedFeeAmt, f.percentageFeeAmt, f.id.gatewayFeeTypeEn);
  }

  get isZero(): boolean {
    switch (this.calculationType) {
      case FeeCalculation.FIXED:
        return (this.fixedAmount ?? 0) == 0;
      case FeeCalculation.PERCENT:
        return (this.percentageAmount ?? 0) == 0;
      case FeeCalculation.HIGHEST:
        return (this.fixedAmount ?? 0) == 0 && (this.percentageAmount ?? 0) == 0;
      case FeeCalculation.LOWEST:
        return (this.fixedAmount ?? 0) == 0 && (this.percentageAmount ?? 0) == 0;
    }
  }
}
